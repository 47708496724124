<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  mounted() {
  }
}
</script>

<style>
.bolder {
  font-weight: bolder;
}



.text_center {
  text-align: center;
}


.flex_col {
  display: flex;
  flex-direction: column;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.flex_center {
  display: flex;
  align-items: center;
}

.flex_yend {
  display: flex;
  align-items: flex-end;
}

.flex_xcenter {
  display: flex;
  justify-content: center;
}

.flex {
  display: flex;
  justify-content: space-between;
}


.flex_end {
  display: flex;
  justify-content: flex-end;
}



.margintop_20 {
  margin-top: 20px;
}

.margintop_10 {
  margin-top: 10px;
}

.margintop_5 {
  margin-top: 5px;
}

.marginleft_10 {
  margin-left: 10px;
}
.marginleft_20 {
  margin-left: 20px;
}

.marginleft_60 {
  margin-left: 60px;
}

.margintop_40{
  margin-top: 40px;
}





.flex_start {
  display: flex;
  align-items: flex-start;
}



.paddingbottom10 {
  padding-bottom: 10px;
}

.flex_warp {
  flex-wrap: wrap;
}


</style>