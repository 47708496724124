import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import VueCookies from 'vue-cookies'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(VueCookies)

Vue.config.productionTip = false


router.beforeEach((to, form, next) => {
  let role
  if(Vue.$cookies.get("userData")){
    role = Vue.$cookies.get("userData").id
  }

  if (!role && (to.path !== '/login' && to.path !== '/register')) {
    next('/login')
  }
  else {
    next();
  }
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
