import Vue from 'vue'
import Router from 'vue-router'

Vue.use( Router )


export default new Router( {
    routes: [
        {
            path: '/',
            redirect: '/index'
        },
        {
            path: '/login',
            component: () => import('../pages/login.vue'),
            meta: { title: '登录' }
        },
        {
            path: '/register',
            component: () => import('../pages/register.vue'),
            meta: { title: '注册' }
        },
        {
            path: '/index',
            component: () => import('../pages/index.vue'),
            meta: { title: '课件列表' }
        },
        {
            path: '/mine',
            component: () => import('../pages/mine.vue'),
            meta: { title: '个人中心' }
        },
        {
            path: '/information',
            component: () => import('../pages/information.vue'),
            meta: { title: '基本资料' }
        },
        {
            path: '/password',
            component: () => import('../pages/password.vue'),
            meta: { title: '密码修改' }
        },
        {
            path: '/learn',
            component: () => import('../pages/learn.vue'),
            meta: { title: '学习时间' }
        },
        {
            path: '/video',
            name: 'video',
            component: () => import('../pages/video.vue'),
            meta: { title: '课件培训' }
        },
        {
            path: '/exam',
            name: 'exam',
            component: () => import('../pages/exam.vue'),
            meta: { title: '在线考试' }
        },
        {
            path: '/examDetails',
            name: 'examDetails',
            component: () => import('../pages/examDetails.vue'),
            meta: { title: '在线考试' }
        }
    ]
} )